<template>
	<div class="about">
		<div class="navbar-bg"></div>
		<v-container fluid class="about-1 pb-12">
			<v-container class="vuetify-container">
				<h1 class="w-100 playfair dark--text section-title" data-aos="fade-right" data-aos-duration="700">
					{{ $t('about') }}
				</h1>
				<v-divider class="mb-md-10 mb-5"></v-divider>
				<v-row class="ma-0 w-100">
					<v-col cols="12" md="6" class="mb-md-0 mb-10 pl-0 pr-md-12 pr-0" data-aos="fade-right" data-aos-duration="700">
						{{ $t('about1') }}<br><br>
						{{ $t('about2') }}<br><br>
						{{ $t('about3') }}<br><br>
						{{ $t('about4') }}<br><br>
						{{ $t('about5') }}<br><br>
						{{ $t('about6') }}
					</v-col>
					<v-col cols="12" md="6" class="video-container pl-0 pr-0 pl-md-12">
						<iframe class="video" data-aos="fade-left" data-aos-duration="700" src="https://www.youtube.com/embed/I1SPo7nAcOA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</v-col>
				</v-row>
				
			</v-container>
		</v-container>
	</div>
</template>
<script>
export default {
	name: 'About',
	data: () => ({
		//
	})
}
</script>
<style lang="scss" scoped>
.about-1 {
	min-height: 100vh;
	background-color: #eee;
}
.video {
	width: 100%;
	height: 100%;
	min-height: 500px;
}
@media(max-width: 768px) {
	.video {
		width: 100%;
		height: 100%;
		min-height: 300px;
	}
}
</style>